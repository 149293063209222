import Splide from '@splidejs/splide';
import barba from '@barba/core';
import GLightbox from 'glightbox';
import 'lazysizes';
import barbaCss from '@barba/css';

barba.use(barbaCss);

barba.hooks.enter((data) => {
    window.scrollTo(0, 0);
});

barba.hooks.beforeEnter((data) => {
    let nl = document.querySelector('#newsletter');
    nl.addEventListener('submit', (e) => {
        sub(e)
    });
});

let gallery;
let thumbs;
let splide;
let lb;

const lbOptions = {
    loop: true,
    zoomable: false,
    skin: 'km',
    openEffect: 'fade',
    slideEffect: 'fade',
    closeEffect: 'fade',
    draggable: false,
    moreLength: 0,
}

// document.addEventListener('click', (e) => {
//     console.log(e.target);
// })

document.addEventListener('DOMContentLoaded', () => {
    barba.init({
        // transitions: [{
        //     sync: true,
        // }],
        views: [
            {
                namespace: 'home',
                beforeEnter() {
                    document.querySelector('nav').classList.remove('visible');
                },
                beforeLeave() {
                    document.querySelector('nav').classList.add('visible');
                }
            },
            {
                namespace: 'referenz',
                afterLeave(data) {
                    splide.forEach(s => {
                        s.destroy();
                    })
                    lb.destroy();
                },
                beforeEnter(data) {
                    
                },
                afterEnter(data) {
                    let main;
                    const mains = document.querySelectorAll('main');
                    if (mains.length > 1) {
                        main = mains[1];
                    } else {
                        main = mains[0];
                    }
                    lb = GLightbox(lbOptions);
                    splide = mountSplide(main);
                    lb.on('slide_changed', ({prev, current}) => {
                        gallery.go(current.index)
                    })
                    
                    // safari bugfix
                    thumbs.root.style.display = 'none';
                    thumbs.root.style.display = 'flex';
                    gallery.go(0);
                }
            },
            {
                namespace: 'referenzen',
                afterEnter() {
                    let links = document.querySelectorAll('a[data-ref-id]')
                    let images = document.querySelectorAll('img[data-ref-id]')
    
                    links.forEach(link => {
                        link.addEventListener('mouseover', e => {
                            images.forEach(image => {
                                if (image.dataset.refId === link.dataset.refId) {
                                    if (image.src == '' || image.src == undefined) image.src = image.dataset.src;
                                    image.classList.add('hovered');
                                }
                            })
                        })
                        link.addEventListener('mouseout', e => {
                            images.forEach(image => {
                                if (image.dataset.refId === link.dataset.refId) {
                                    image.classList.remove('hovered');
                                }
                            })
                        })
                    })
                }
            }
        ]
    });
})


const mountSplide = (main) => {
    var imageCount = main.dataset.imageCount;

    if (imageCount > 1) {
        thumbs = new Splide('.splide.thumbnails', {
            type: 'loop',
            height: '40px',
            autoWidth: true,
            width: '50vw',
            autoWidth: true,
            direction: 'ltr',
            pagination: false,
            arrows: true,
            isNavigation: true,
            focus: 'center',
            slideFocus: false,
            breakpoints: {
                900: {
                    width: '100vw',
                }
            }
        }).mount();

        gallery = new Splide('.left.splide', {
            type: 'loop',
            height: '100vh',
            autoHeight: true,
            focus: 'center', // this 1
            width: '50vw',
            direction: 'ltr',
            pagination: false,
            arrows: false,
            drag: true,
            breakpoints: {
                900: {
                    width: '100vw',
                    height: '45vh',
                }
            }
        })

        gallery.sync(thumbs).mount();

        gallery.go(0);

        const captions = document.querySelectorAll('.caption');

        thumbs.on('move', function(newIndex) {
            captions.forEach(caption => {
                if (caption.dataset.captionFor == newIndex) {
                    caption.classList.add('visible')
                } else {
                    caption.classList.remove('visible')
                }
            })
        });

        gallery.on('lazyload:loaded', (img) => {
            console.log(img)
        })

        let initialMoved = false;

        gallery.on('inactive', (slide) => {
            let child = slide.slide.querySelector('img');
            if (initialMoved == false && child.classList.contains('left__image')) {
                let captions = document.querySelector('.captions')
                captions.classList.remove('initial')
                child.classList.remove('left__image')
                initialMoved = true;
            }
        })

        return [thumbs, gallery];
    }
}

/*
 * Mailchimp AJAX form submit VanillaJS
 * Vanilla JS
 * Author: Michiel Vandewalle
 * Github author: https://github.com/michiel-vandewalle
 * Github project: https://github.com/michiel-vandewalle/Mailchimp-AJAX-form-submit-vanillaJS
 */

const sub = (e) => {
    e.preventDefault();
    
    // Check for spam
    if(e.target.querySelector('#js-validate-robot').value !== '') { return false }

    // Get url for mailchimp
    var url = e.target.action.replace('/post?', '/post-json?');

    // Add form data to object
    var data = '';
    var inputs = e.target.querySelectorAll('#js-form-inputs input');
    for (var i = 0; i < inputs.length; i++) {
        data += '&' + inputs[i].name + '=' + encodeURIComponent(inputs[i].value);
    }

    // Create & add post script to the DOM
    var script = document.createElement('script');
    script.src = url + data;
    document.body.appendChild(script);

    // Callback function
    var callback = 'callback';
    window[callback] = function(data) {

        // Remove post script from the DOM
        delete window[callback];
        document.body.removeChild(script);

        // Display response message
        document.getElementById('js-subscribe-response').innerHTML = data.msg
    };
}